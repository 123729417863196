<template>
  <div class="header">
    <div class="header-father">
      <div class="header-container">
        <!-- logo部分 -->
        <h1 class="logo" @click="$router.push('/index')"></h1>
        <!-- 菜单导航 -->
        <ul class="menu">
          <li>
            <a @click="toHome('floor1Swiper')">{{ $t('header.home') }}</a>
            <div class="active-line" :class="{ 'active' : currLink === 'floor1Swiper' }"></div>
          </li>
          <li>
            <a @click="toDigtProduct('digtProduct')">{{ $t('header.digt') }}</a>
            <div class="active-line" :class="{ 'active' : currLink === 'digtProduct' }"></div>
          </li>
          <li>
            <a @click="toIndustry('idstryServece')">{{ $t('header.idstry') }}</a>
            <div class="active-line" :class="{ 'active' : currLink === 'idstryServece' }"></div>
          </li>
          <li>
            <a @click="toAbout('aboutUs')">{{ $t('header.about') }}</a>
            <div class="active-line" :class="{ 'active' : currLink === 'aboutUs' }"></div>
          </li>
          <li class="lang">
            <!-- 切换中英文 @click="changeLocale('zh-CN')" -->
            <span :class="{'active': currLang === 'zh-CN'}">CN</span>
            <i>/</i>
            <!-- 切换中英文 @click="changeLocale('en')" -->
            <span :class="{'active': currLang === 'en'}">EN</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      currLang: 'zh-CN',
      currLink: 'floor1Swiper',
    }
  },
  mounted(){
    this.$bus.$on('changeCurrLink', (prop)=>{
      this.currLink = prop
    })
  },
  methods: {
    changeLocale(lang) {
      this.currLang = lang
      this.$i18n.locale = lang
      this.$bus.$emit('getLang', lang)
    },
    // 点击分发点击的跳转元素的id
    clickSilder(elId) {
      this.currLink = elId
      this.$bus.$emit('scrollTo', elId)
    },
    // 跳转首页
    toHome(flag){
      this.currLink = flag
      this.$route.path === '/index'
       ? this.$router.replace('/index') 
       : this.$router.push('/index')

      setTimeout(()=>{
        this.clickSilder(flag)
      }, 50)
    },
    // 跳转工业服务
    toIndustry(flag) {
      this.$route.path === '/index'
       ? this.$router.replace('/index') 
       : this.$router.push('/index')

      setTimeout(()=>{
        this.clickSilder(flag)
      }, 50)
    },
    // 跳转数字产品
    toDigtProduct(flag) {
      this.$route.path === '/index'
       ? this.$router.replace('/index') 
       : this.$router.push('/index')

      setTimeout(()=>{
        this.clickSilder(flag)
      }, 50)
    },
    // 跳转关于我们页面
    toAbout(flag) {
      this.currLink = flag
      this.$router.push('/about')
    },
    
  }
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 56px;
}

.header .header-father {
  width: 100%;
  height: 56px;
  background-color: rgb(79, 209, 233);
  position: fixed;
  z-index: 999;
}

 .header .header-container {
  width: 1383px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .header-container .logo {
  width: 270px;
  height: 24px;
  background: url("~@/assets/images/logo.png") no-repeat left center;
  background-size: contain;
  cursor: pointer;
}

.header .header-container .menu {
  display: flex;
  height: 80px;
}

.header .header-container .menu li {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header .header-container .menu li:hover {
  cursor: pointer;
}

.header .header-container .menu li + li {
  margin-left: 60px;
}

.header .header-container .menu li a {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.95);
}

.header .header-container .menu li a:hover,
.header .header-container .menu li a:active,
.header .header-container .menu li a:link,
.header .header-container .menu li a:visited {
  color: rgba(255, 255, 255);
  text-decoration: none;
}

.header .header-container .menu li a + .active-line {
  height: 1.5px;
  margin: 0 auto;
  width: 0;
  background-color: #fff;
  transition: all 0.2s ease;
}

.header .header-container .menu li a + .active-line.active {
  height: 1.5px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}

.header .header-container .menu li.lang {
  display: inline-block;
  white-space: nowrap;
  line-height: 80px;
}

.header .header-container .menu li.lang span {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.6);
}

.header .header-container .menu li.lang span:hover {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.header .header-container .menu li.lang span.active {
  color: rgba(255, 255, 255, 1);
}

.header .header-container .menu li.lang i {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  padding: 0 8px;
}
</style>