<template>
  <div class="footer">
    <div class="footer-container">
      <div class="link">
        <ul class="link-wraper">
          <li>
            <dl>
              <dt class="logo"><img src="@/assets/images/logo.png" alt=""></dt>
            </dl>
          </li>
          <ul>
            <li>
              <dl>
                <dt class="title">{{ $t('footer.digt') }}</dt>
                <dd><a @click="toDigitProd('digtProduct')">{{ $t('footer.digtIbmp') }}</a></dd>
                <dd><a @click="toDigitProd('digtProduct')">{{ $t('footer.digtCockpit') }}</a></dd>
                <dd><a @click="toDigitProd('digtProduct')">{{ $t('footer.digtEngineer') }}</a></dd>
                <dd><a @click="toDigitProd('digtProduct')">{{ $t('footer.digtChain') }}</a></dd>
                <dd><a @click="toDigitProd('digtProduct')">{{ $t('footer.digtQms') }}</a></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt class="title">{{ $t('footer.serve') }}</dt>
                <dd><a @click="toIndustryServe('idstryServece')">{{ $t('footer.serveTransform') }}</a></dd>
                <dd><a @click="toIndustryServe('idstryServece')">{{ $t('footer.serveStaff') }}</a></dd>
                <dd><a @click="toIndustryServe('idstryServece')">{{ $t('footer.serveFactory') }}</a></dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt class="title">{{ $t('footer.about') }}</dt>
                <dd><a @click="toAbout">{{ $t('footer.aboutUs') }}</a></dd>
                <dd><a @click="toAbout">{{ $t('footer.aboutContact') }}</a></dd>
              </dl>
            </li>
          </ul>
        </ul>
      </div>
      <div class="copyright">
        <p>{{ $t('footer.address') }}</p>
        <p>{{ $t('footer.copyright') }}</p>
        <p>{{ $t('footer.record') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foot',
  data() {
    return {

    }
  },
  methods: {
    // 跳转关于我们
    toAbout() {
      this.$router.push('/about')
      this.$bus.$emit('changeCurrLink', 'aboutUs')
    },
    // 跳转工业服务
    toIndustryServe(flag){
      this.$route.path === '/index'
       ? this.$router.replace('/index') 
       : this.$router.push('/index')

      setTimeout(()=>{
        this.$bus.$emit('scrollTo', flag)
        this.$bus.$emit('changeCurrLink', flag)
      }, 50)
    },
    // 跳转数字产品
    toDigitProd(flag){
      this.$route.path === '/index'
       ? this.$router.replace('/index') 
       : this.$router.push('/index')

      setTimeout(()=>{
        this.$bus.$emit('scrollTo', flag)
        this.$bus.$emit('changeCurrLink', flag)
      }, 50)
    }
  }
}
</script>

<style scoped>
.footer {
  background: rgb(79, 209, 233);
}

.footer .footer-container {
  margin: 0 100px;
  padding: 60px 0 30px;
}

.footer .footer-container .link {
  display: flex;
  justify-content: space-between;
}

.footer .footer-container .link .link-wraper {
  flex: 8;
  display: flex;
  align-items: stretch;
}

.footer .footer-container .link .link-wraper > li {
  flex: 21;
  margin-right: 40px;
}

.footer .footer-container .link .link-wraper > ul {
  flex: 30;
  display: flex;
}

.footer .footer-container .link .link-wraper > ul > li {
  flex: 1;
}

.footer .footer-container .link .link-wraper li .logo img {
  width: 200px;
  margin-left: 150px;
}

.footer .footer-container .link .link-wraper li .title {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 12px;
}

.footer .footer-container .link .link-wraper li dd a {
  color: rgba(255, 255, 255, 0.95);
  font-size: 14px;
  line-height: 2.2;
}

.footer .footer-container .link .link-wraper li dd a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.footer .footer-container .link .code-wraper {
  flex: 2;
  align-items: stretch;
  display: flex;
  justify-content: space-between;
}

.footer .footer-container .link .code-wraper div {
  flex: 1;
}

.footer .footer-container .link .code-wraper div img {
  width: 100%;
}

.footer .footer-container .link .code-wraper div + div {
  margin-left: 16px;
}

.footer .footer-container .copyright {
  margin-top: 40px;
}

.footer .footer-container .copyright p {
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 1.6;
  font-weight: normal;
}
</style>