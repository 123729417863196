<template>
  <div>
    <!-- 顶部栏 -->
    <Header ref="header"></Header>
    <!-- 控制PC页面的组件切换路由 -->
    <router-view></router-view>
    <!-- 底部栏 -->
    <Footer></Footer>
  </div>
</template>

<script>
//引入非路由组件
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
export default {
  name: "PcHome",
  components: {
    Header,
    Footer,
  },
  data() {
    return {}
  },

  mounted() {
    this.onScroll()
  },
  methods: {
    // 单页面点击滚动指定位置
    onScroll() {
      this.$bus.$on('scrollTo', (anchor) => {
        // 根据id获取dom节点
        const anchorEle = document.querySelector(`#${ anchor }`);
        let flag = !!anchorEle
        if (flag) {
          // 获取元素居中位置时候距离浏览器顶部高度
          let HIGT = ( document.documentElement.clientHeight - anchorEle.offsetHeight ) / 2

          // 将元素滚动指定位置
          anchorEle.scrollIntoView({
            block: "end",
            behavior: "smooth", 
          });

          // 将元素滚动视口居中位置
          window.scrollTo({
            left: 0, 
            top:anchorEle.offsetTop - HIGT, 
            behavior: "smooth"
          });
        }
      })
    }
  }
};
</script>
<style scoped>
@font-face {
  font-family: Emoji;
  src: local("Apple Color Emojiji"), local("Segoe UI Emoji"),
    local("Segoe UI Symbol"), local("Noto Color Emoji");
  unicode-range: U+1F000-1F644, U+203C-3299;
}
body {
  font-family: system-ui, —apple-system, Segoe UI, Rototo, Emoji, Helvetica,
    Arial, sans-serif;
}
.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
}
.font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
}
</style>
